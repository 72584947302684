.spinner-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - #{$navbar-height} - 5.5rem);
  position: fixed;
  width: calc(100% - 4rem);
  @media (max-width: 991px) {
    width: 100%;
  }
  .donut {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    border: 0.3rem solid rgba(#979fd0, 0.3);
    border-top-color: #979fd0;
    animation: 1.5s spin infinite linear;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}